<template>
  <div class="users-card">
    <ListCard
      v-bind="$attrs"
      :title="titleText"
      :list="filteredUsers"
      :selected="selected"
      itemTitle="email"
      :itemText="showPrivileges ? getUserPrivileges : null"
      searchFields="email"
      :singleSelection="singleSelection"
      :activeClass="singleSelection ? 'selected-user' : ''"
      :textIcon="group ? 'fa fa-tag' : 'fa fa-users'"
      :emptyListText="
        emptyListText ||
          (group ? $t('no_users_in_group') : $t('no_users_in_process_area'))
      "
      :sort="true"
      :disabled="isUserDisabled"
      :disabledText="$t('you_cant_edit_contract_owner')"
      :actions="
        allowRemove
          ? [
              {
                type: 'danger',
                title: $tc('remove_user_from_hierarchy', 1, {
                  hierarchy: $tc(group ? 'group' : 'process_area').toLowerCase()
                }),
                disabled: isUserDisabled,
                trigger: (item) => confirmRemove([item]),
                icon: 'trash',
                swipe: true
              }
            ]
          : []
      "
      @select="select"
      @filter="searchFilteredUsers = $event"
      :key="titleText"
    >
      <template #bulk-actions="{ items }">
        <transition name="slide">
          <BaseButton
            v-if="selectedUsers.length > 1 && allowRemove"
            type="danger"
            small
            :title="
              $tc('remove_user_from_hierarchy', 2, {
                hierarchy: group ? $tc('group') : $tc('process_area')
              })
            "
            @click.stop="confirmRemove(items)"
          >
            <i class="fa fa-trash"></i>
          </BaseButton>
        </transition>
        <BaseButton
          v-if="showAddHierarchyEntity"
          class="add-hierarchy-entity-btn"
          type="info"
          small
          :title="
            $t(`titles.add_${group ? 'process_areas' : 'groups'}_to_users`)
          "
          data-toggle="tooltip"
          data-delay-show="1000"
          @click="hierarchyEntityShortcut"
        >
          <i class="fa fa-plus corner-icon"></i>
          <i :class="`fa fa-${group ? 'tag' : 'users'}`"></i>
        </BaseButton>
      </template>
      <template #footer v-if="$slots.footer">
        <slot name="footer"></slot>
      </template>
    </ListCard>
  </div>
</template>

<script>
import ListCard from "@/components/widgets/list-card";
import BaseButton from "@/components/base/buttons/base-button";
import MixinAlert from "@/project/mixin-alert.js";

import { mapGetters } from "vuex";

export default {
  name: "UsersCard",
  components: {
    ListCard,
    BaseButton
  },
  mixins: [MixinAlert],
  inheritAttrs: false,
  props: {
    group: {
      type: Object
    },
    processArea: {
      type: Object
    },
    selected: {
      type: Array,
      default: () => []
    },
    singleSelection: {
      type: Boolean,
      default: true
    },
    showPrivileges: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ""
    },
    emptyListText: {
      type: String,
      default: ""
    },
    filter: {
      type: [String, Function],
      default: "include",
      validator(val) {
        if (typeof val == "string") {
          return val
            .split(",")
            .every((v) => ["include", "exclude", "owner"].includes(v));
        }
        return typeof val == "function";
      }
    },
    allowRemove: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedUsers: [],
      searchFilteredUsers: []
    };
  },
  computed: {
    filteredUsers() {
      return this.userFilter ? this.users.filter(this.userFilter) : this.users;
    },
    userFilter() {
      let filters =
        typeof this.filter == "string" ? this.filter.split(",") : [];
      let finalFilter;
      if (this.group || this.processArea) {
        finalFilter = (user) => {
          let result;
          if (this.group) {
            result = user.process_area.reduce(
              (foundGroup, processArea) =>
                foundGroup ||
                processArea.user_groups.find(
                  (group) => group.id == this.group.id
                ),
              null
            );
          } else if (this.processArea) {
            result = user.process_area.some(
              (pa) => pa.id == this.processArea.id
            );
          }
          if (filters.some((f) => f == "include")) return result;
          return !result;
        };
      } else if (typeof this.filter == "function") finalFilter = this.filter;

      if (filters.some((f) => f == "owner"))
        // filter out contract owner (in case logged user isn't the one)
        return (user) => finalFilter(user) && !this.isUserDisabled(user);
      return finalFilter;
    },
    titleText() {
      return this.title != ""
        ? this.title
        : this.group?.name ?? this.processArea?.name ?? this.$tc("user", 2);
    },
    showAddHierarchyEntity() {
      return (
        (this.group || this.processArea) &&
        this.filter?.split?.(",")?.some((f) => f == "include") &&
        this.filteredUsers.length
      );
    },
    ...mapGetters("user", ["users", "loggedUser"])
  },
  watch: {
    selected: {
      deep: true,
      immediate: true,
      handler(val) {
        this.selectedUsers = val;
      }
    },
    filteredUsers() {
      this.selectedUsers = [];
    }
  },
  methods: {
    getUserPrivileges(user) {
      let filteredByGroup = this.group || (!this.group && !this.processArea);
      if (filteredByGroup)
        return user.process_area
          .filter((p) =>
            this.group ? p.user_groups.some((g) => g.id == this.group.id) : true
          )
          .map((p) => p.name)
          .join(", ");
      else
        return user.process_area
          .find((pa) => pa.id == this.processArea.id)
          ?.user_groups?.map?.((g) => g.name)
          .join(", ");
    },
    select(users, singleSelection) {
      this.selectedUsers = users;
      this.$emit("select", users, singleSelection);
    },
    confirmRemove(users) {
      // create a email list up to 4 users
      let description = users
        .map((u) => u.email)
        .filter(
          (_, index) => (!index || index < users.length - 1) && index < 3
        );
      // separe it with comma/and if there is more than one
      if (users.length > 1) {
        description = description
          .join(", ")
          .concat(` ${this.$t("and")} ` + users.at(-1).email);
      } else {
        description = description[0];
      }

      // add "many others" text if there is more than 4
      if (users.length > 4) {
        description +=
          " " +
          this.$tc("and_many_others", users.length - 4, {
            amount: users.length - 4
          });
      }

      this.$swal({
        title: this.$t("are_you_sure"),
        content: this.warningContent(
          ["user", 2],
          description,
          "you_wont_be_able_to_revert_this"
        ),
        icon: "warning",
        buttons: [this.$t("cancel"), this.$t("yes_delete_it")],
        dangerMode: true
      }).then((ok) => {
        if (ok) {
          this.$emit("remove", users);
        }
      });
    },
    isUserDisabled(user) {
      // check if user should be disabled for editing
      // (only the contract owner can edit its privileges)
      return (
        this.loggedUser.id != user.id &&
        user.id == this.loggedUser.user_profile.contract.owner_id
      );
    },
    hierarchyEntityShortcut(e) {
      $(e.target).tooltip("hide");
      this.$emit(`shortcut:${this.group ? "processArea" : "group"}`);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .card.list-card .list-group-item.selected-user {
  &,
  &:hover {
    --bg-color: #d1eeff;
    background-color: var(--bg-color);
  }

  .list-group-item-heading {
    color: #333;
  }

  .list-group-item-text {
    color: #555;
  }
}

::v-deep .bulk-actions {
  .btn.btn-danger {
    border-color: #d73925;

    &:hover {
      background-color: #d73925;
    }
  }

  .btn.btn-info:hover {
    background-color: #00acd4;
  }

  .btn.btn-info.add-hierarchy-entity-btn {
    position: relative;
    color: white;

    .fa.fa-tag {
      position: relative;
      top: 1px;
      right: 0px;
    }

    .corner-icon {
      position: absolute;
      top: 3px;
      right: 4px;
      font-size: 0.6em;
    }
  }
}

.card.box-default::v-deep {
  .bulk-actions .btn.btn-danger {
    border-color: #dd4b39;
    background-color: transparent;
    color: #d73925;

    &:hover {
      color: #ececec;
      background-color: #d73925;
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 80ms;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(5px);
  opacity: 0;
}
</style>
