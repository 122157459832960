<template>
  <Card
    :class="['list-card', { searching: searchEnabled }]"
    :style="getStyle()"
    :type="type"
    v-bind="$attrs"
  >
    <template #header>
      <Checkbox
        :class="[
          'select-all',
          { 'subtitle-mobile': isMobile && enableSubtitle && absSelected }
        ]"
        v-model="selectAll"
        @change="updateSelected"
        :indeterminate="indeterminate"
        :inputTitle="
          selectAll || indeterminate
            ? $tc('titles.clear_selection')
            : $tc('titles.select_all', 2)
        "
        :title="titleHint || title"
        :disabled="!list.length || readOnly || disabled('all')"
      >
        {{ title }}
        <Tooltip v-if="tooltip && !isMobile" :title="tooltip" />

        <span
          class="subtitle in-title"
          v-html="subtitle"
          v-if="enableSubtitle && absSelected && isMobile"
        ></span>
      </Checkbox>
      <Tooltip
        v-if="tooltip && isMobile"
        style="margin-right: 0.6em"
        :title="tooltip"
      />
      <span
        class="subtitle"
        v-html="subtitle"
        v-if="enableSubtitle && absSelected && !isMobile"
      ></span>
      <div class="bulk-actions">
        <slot
          name="bulk-actions"
          :items="selectedItems"
          v-if="$scopedSlots['bulk-actions']"
        ></slot>
        <BaseButton
          class="list-card__search-btn"
          small
          @click="toggleSearch"
          :title="$t('search')"
          :disabled="!list.length"
        >
          <i class="fa fa-search"></i>
        </BaseButton>
      </div>
    </template>
    <template #before-body>
      <ListFilter
        :class="['search', { enabled: searchEnabled }]"
        :list="list"
        :fields="searchFields"
        :showSearchButton="false"
        :flatButton="true"
        :placeholder="$t('search') + ' ' + $tc('user').toLowerCase()"
        searchingClass="borderfull"
        @filter="filter"
        @keydown.esc.exact="disableSearch"
        @clear="disableSearch(true)"
        ref="search"
      />
    </template>
    <ListGroup :linked="true" v-if="list.length || !emptyListText">
      <ListGroupItem
        v-for="(item, index) in filteredList"
        :key="index"
        :class="{
          disabled: disabled(item),
          readonly: readOnly,
          [`swipe-action-${(swipeAction && swipeAction.type) ||
            ''}`]: swipeAction,
          [`fa-${swipeAction && swipeAction.icon}`]: swipeAction
        }"
        @click="selectItem(item, $event.target)"
        :active="isActive(item)"
        :activeClass="activeClass"
        data-toggle="tooltip"
        :title="disabled(item) ? disabledText : ''"
        v-swipe="{
          threshold: '50%',
          allowedTime: null,
          onSwipeMove,
          onSwipe: (arg) => onSwipe(arg, index)
        }"
        @touchstart="removeSwipeTransition"
        ><Checkbox
          :class="{ spaced: getItemText(item) != '' }"
          v-model="selectedItems"
          name="list-item"
          :value="getItemValue(item)"
          :inputTitle="
            selectedItems.includes(item)
              ? $tc('titles.cancel_selection')
              : $tc('titles.select')
          "
          :indeterminate="isIndeterminate(item)"
          :disabled="readOnly || disabled(item)"
          @click.stop
        >
          <template v-if="typeof item == 'object'">
            <span class="list-group-item-heading">
              <i :class="['icon', titleIcon]" v-if="titleIcon"></i>
              {{ getItemTitle(item) }}</span
            >
            <p
              class="list-group-item-text"
              :title="getItemText(item)"
              v-if="getItemText(item)"
            >
              <i :class="['icon', textIcon]" v-if="textIcon"></i>
              {{ getItemText(item) }}
            </p>
            <div class="actions" v-if="actions.length">
              <BaseButton
                v-for="(action, index) in actions"
                :type="action.type"
                :title="action.title"
                :disabled="action.disabled(item)"
                small
                @click.stop="action.trigger(item)"
                :key="index"
              >
                <i :class="`fa fa-${action.icon}`"></i>
              </BaseButton>
            </div>
          </template>
          <template v-else>
            {{ item }}
            <div class="actions" v-if="actions.length">
              <BaseButton
                v-for="(action, index) in actions"
                :type="action.type"
                :title="action.title"
                :disabled="action.disabled(item)"
                small
                @click.stop="action.trigger([item])"
                :key="index"
              >
                <i :class="`fa fa-${action.icon}`"></i>
              </BaseButton>
            </div>
          </template> </Checkbox
      ></ListGroupItem>
    </ListGroup>
    <p class="empty-list" v-else>
      {{ emptyListText }}
    </p>
    <template #footer v-if="$slots.footer">
      <slot name="footer"></slot>
    </template>
  </Card>
</template>

<script>
import Card from "@/components/widgets/card";
import Checkbox from "@/components/base/checkbox";
import BaseButton from "@/components/base/buttons/base-button";
import { ListGroup, ListGroupItem } from "@/components/base/list-group";
import ListFilter from "@/components/widgets/list-filter";
import Tooltip from "@/components/tooltip";

import MixinLocaleSort from "@/project/mixin-locale-sort";
import MixinMobile from "@/project/mixin-mobile";
import SwipeDirective from "@/directives/swipe";

import isEqual from "lodash/isEqual";
import { whichTransitionEvent } from "@/utils";

export default {
  name: "ListCard",
  mixins: [MixinLocaleSort, MixinMobile],
  directives: { swipe: SwipeDirective },
  components: {
    Card,
    Checkbox,
    ListGroup,
    ListGroupItem,
    BaseButton,
    ListFilter,
    Tooltip
  },
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: "primary"
    },
    title: {
      type: String,
      default: ""
    },
    subtitleSuffix: {
      type: String,
      default: "o"
    },
    subtitleTerm: {
      type: String,
      required: false
    },
    enableSubtitle: {
      type: Boolean,
      default: true
    },
    titleHint: {
      type: String,
      default: ""
    },
    tooltip: {
      type: String,
      default: ""
    },
    list: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Array,
      default: () => []
    },
    indeterminated: {
      type: Array,
      default: () => []
    },
    itemTitle: {
      type: [String, Function],
      default: ""
    },
    itemText: {
      type: [String, Function],
      default: ""
    },
    itemValue: {
      type: [String, Function],
      default: ""
    },
    titleIcon: {
      type: String,
      default: ""
    },
    textIcon: {
      type: String,
      default: ""
    },
    emptyListText: {
      type: String,
      defautlt: ""
    },
    searchFields: {
      type: String,
      default: "any"
    },
    // if true, selecting one item will unselect others
    // and not mark checbox as true
    singleSelection: {
      type: Boolean,
      default: false
    },
    activeClass: {
      type: String,
      default: "active"
    },
    reset: {
      required: false
    },
    disabled: {
      type: Function,
      default: () => false
    },
    disabledText: {
      type: String,
      default() {
        return this.$t(
          "you_do_not_have_enough_privileges_to_execute_this_action"
        );
      }
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    sort: {
      type: Boolean,
      default: false
    },
    sortBy: {
      type: String,
      default: ""
    },
    asc: {
      type: Boolean,
      default: true
    },
    actions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectAll: false,
      selectedItems: [],
      selectedItem: null,
      filteredList: [],
      dirtyItems: new Set(),
      indeterminate: false,
      searchEnabled: false,
      // if a change to selectedItems list was manual
      // (used to prevent unnecessary update and state syncing)
      manualSelection: false,
      mobileThreshold: 768
    };
  },
  computed: {
    subtitle() {
      // "3 <users> selected"
      return `${this.absSelected}${
        this.subtitleTerm
          ? " " + this.$tc(this.subtitleTerm, this.absSelected).toLowerCase()
          : ""
      } ${this.$tc("selected", this.absSelected, {
        gender: this.subtitleSuffix
      }).toLowerCase()}`;
    },
    absSelected() {
      return this.selectedItems.length || (this.selectedItem ? 1 : 0);
    },
    swipeAction() {
      return this.actions.find((a) => a.swipe == true);
    }
  },
  watch: {
    selectedItems: {
      immediate: true,
      handler(items, oldItems) {
        this.indeterminate =
          items.length > 0 && items.length < this.list.length;

        // delay change to another tick in case handler was called
        // after "select all" action (handled in updateSelected()),
        // so DOM is correctly updated
        this.$nextTick(
          () =>
            (this.selectAll =
              items.length == this.list.length && this.list.length > 0)
        );

        if (!this.manualSelection && oldItems) {
          if (this.selectedItem) {
            if (!items.includes(this.selectedItem))
              items.push(this.selectedItem);
            this.selectedItem = null;
          }
          this.emitChanges(items);
          this.checkDirtyItems();
        } else this.manualSelection = false;
      }
    },
    selectedItem(item) {
      if (item) {
        this.manualSelection = true;
        this.selectedItems = [];
        this.dirtyItems.add(item);
        this.emitChanges([item], true);
      }
    },
    list: {
      immediate: true,
      deep: true,
      handler(val) {
        this.setFilteredList([...val]);
        this.manualSelection = true;
        this.selectedItems = [];
        this.dirtyItems.clear();
      }
    },
    selected: {
      immediate: true,
      deep: true,
      handler: "resetSelected"
    },
    reset: "resetSelected"
  },
  methods: {
    resetSelected() {
      this.manualSelection = true;
      if (this.singleSelection && this.selected.length == 1) {
        this.selectedItems = [];
        this.selectedItem = this.selected[0];
      } else {
        this.selectedItems = [...this.selected];
        this.selectedItem = null;
      }
      this.dirtyItems.clear();
      this._lastValue = [...this.selected];
    },
    filter(list) {
      this.setFilteredList(list);
      this.$emit("filter", list);
    },
    setFilteredList(list) {
      if (this.sort && (this.sortBy || this.itemTitle)) {
        this.localeSort(
          list,
          (i) => i[this.sortBy] ?? this.getItemTitle(i),
          this.asc
        );
      }
      this.filteredList = list;
    },
    getStyle() {
      return !this.$slots.footer ? { "--body-p-bottom": "0px" } : {};
    },
    getItemTitle(item) {
      return (
        item[this.itemTitle] ??
        (typeof this.itemTitle == "function"
          ? this.itemTitle(item) ?? ""
          : item.title) ??
        ""
      );
    },
    getItemText(item) {
      return (
        item[this.itemText] ??
        (typeof this.itemText == "function"
          ? this.itemText(item) ?? ""
          : item.text) ??
        ""
      );
    },
    getItemValue(item) {
      return (
        item[this.itemValue] ??
        (typeof this.itemValue == "function"
          ? this.itemValue(item) ?? ""
          : item) ??
        ""
      );
    },
    updateSelected() {
      if (this.indeterminate) {
        this.selectedItems = [];
        this.indeterminate = false;
        // since selectAll is already false,
        // alternates values across ticks to force DOM update
        this.$nextTick()
          .then(() => {
            this.selectAll = true;
            return this.$nextTick();
          })
          .then(() => (this.selectAll = false));
      } else if (
        this.selectAll &&
        !(this.selectedItems.length == this.list.length)
      ) {
        this.selectedItems = this.list
          .filter((item) => !this.disabled(item))
          .map((item) => this.getItemValue(item));
      } else if (!this.selectAll && this.selectedItems.length > 0) {
        this.selectedItems = [];
      }
    },
    selectItem(item, el) {
      if (this.readOnly || this.disabled(item)) return;
      if (this.singleSelection) this.selectedItem = this.getItemValue(item);
      else {
        let index = this.selectedItems.indexOf(item);
        if (index != -1) {
          this.selectedItems.splice(index, 1);
        } else {
          this.selectedItems.push(item);
        }
      }
      this.$nextTick(() => {
        el.scrollIntoViewIfNeeded();
      });
    },
    isIndeterminate(item) {
      return this.indeterminated.includes(item) && !this.dirtyItems.has(item);
    },
    isActive(item) {
      return (
        isEqual(this.selectedItem, item) ||
        this.selectedItems.some((i) => isEqual(i, item))
      );
    },
    toggleSearch() {
      this.searchEnabled = !this.searchEnabled;
      if (this.searchEnabled)
        this.$refs.search.$el.querySelector("input").focus();
    },
    disableSearch(force) {
      // if no item is filtered (aka. search field is empty)
      if (this.filteredList.length == this.list.length || force == true) {
        this.searchEnabled = false;
      }
    },
    checkDirtyItems() {
      // go through each item and check if it's dirty
      this.list.forEach((item) => {
        if (
          this.selectedItems.includes(item) &&
          this.indeterminated.includes(item)
        ) {
          this.dirtyItems.add(item);
        } else if (
          (this.selectedItems.includes(item) &&
            !this.selected.includes(item)) ||
          (!this.selectedItems.includes(item) && this.selected.includes(item))
        ) {
          this.dirtyItems.add(item);
        } else if (!this.indeterminated.includes(item)) {
          this.dirtyItems.delete(item);
        }
      });
      this.$emit("gotDirty", Array.from(this.dirtyItems));
    },
    emitChanges(items, singleSelection = false) {
      // prevents duplicate emitted changes
      if (
        !this._lastValue ||
        !(
          this._lastValue.length == items.length &&
          this._lastValue.every((item) => items.includes(item)) &&
          items.length > 0
        )
      ) {
        this.$emit("select", items, singleSelection);
      }
      this._lastValue = [...items];
    },
    onSwipeMove({ target, distanceX }) {
      if (this.swipeAction && distanceX < 0 && target.dataset.busy != "true")
        target.style.setProperty("--swp-dist", `${distanceX}px`);
    },
    onSwipe({ target, direction }, itemIndex) {
      if (!this.swipeAction) return;

      const addTransition = () => {
        target.style.setProperty("--transition", ".7s");
        target.addEventListener(
          whichTransitionEvent(),
          this.removeSwipeTransition
        );
      };

      const trigger = () => {
        target.removeEventListener(whichTransitionEvent(), trigger);
        this.swipeAction.trigger(this.filteredList[itemIndex]);
        addTransition();
        target.style.setProperty("--swp-dist", "0px");
      };

      if (target.style.getPropertyValue("--swp-dist") != "0px") {
        addTransition();
      }

      if (direction == "none") {
        target.style.setProperty("--swp-dist", "0px");
        target.classList.add("transitioning");
      } else if (direction == "left") {
        const dist = target.getBoundingClientRect().width * -1;
        target.dataset.busy = true;
        target.style.setProperty("--swp-dist", dist + "px");
        target.classList.add("transitioning");
        target.addEventListener(whichTransitionEvent(), trigger);
      }
    },
    removeSwipeTransition(e) {
      if (e.target.style.getPropertyValue("--transition")) {
        // removes transition for next swipe action
        e.target.style.removeProperty("--transition");
        e.target.classList.remove("transitioning");
        e.target.dataset.busy = false;
        e.target.removeEventListener(
          whichTransitionEvent(),
          this.removeSwipeTransition
        );
      }
    }
  },
  mounted() {
    $(this.$el)
      .find('[data-toggle="tooltip"]')
      .tooltip({ delay: { show: 500, hide: false } });
  }
};
</script>

<style lang="scss" scoped>
.card.list-card::v-deep {
  --header-height: 50px;
  --search-height: 34px;
  --body-p-bottom: 4px;

  &.searching {
    --header-height: calc(50px + var(--search-height));
  }

  .checkbox {
    input[type="checkbox"] {
      margin-left: -26px;
      width: 15px;
      height: 15px;
    }

    label {
      padding-left: 26px;
      padding-right: 10px;
    }
  }

  .box-header {
    padding-left: 13px;
    z-index: 1;

    .box-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .select-all {
      margin: 0;
      overflow-x: hidden;

      label {
        cursor: revert;
        overflow: hidden;
        vertical-align: top;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      input[type="checkbox"] {
        margin-top: 1px;
      }

      &.subtitle-mobile input[type="checkbox"] {
        margin-top: 6px;
      }
    }

    .bulk-actions {
      display: flex;

      * {
        &.btn-sm {
          width: 33px;
        }

        &.btn:not(:last-child) {
          margin-right: 15px;
        }
      }
    }

    .subtitle {
      font-size: 0.82em;
      color: inherit;
      opacity: 0.8;

      &.in-title {
        margin-top: 3px;
        display: block;
      }

      &:not(.in-title) {
        flex: 1 0 24%;
        padding-bottom: 2px;
        margin-left: -3px;
      }
    }
  }

  &.box-default .list-card__search-btn {
    border-color: #a8a8a8;
  }

  .search {
    margin-top: -34px;
    transition: margin-top 150ms;
    z-index: 0;

    input {
      border-inline: 0;
    }

    &.borderfull input {
      border-right: 1px solid #ccc;

      &:focus {
        border-color: #3c8dbc;
      }
    }

    &.enabled {
      margin-top: 0;
    }

    button {
      border-radius: 0;
    }
  }

  .box-body {
    padding: 0 0 var(--body-p-bottom) 0;
    scroll-behavior: smooth;
  }

  .empty-list {
    font-size: 1.8rem;
    padding-right: 4rem;
    text-align: center;
    position: relative;
    top: calc(50% - 0.9rem);
    padding-left: 4rem;
  }

  .list-group {
    margin-bottom: 0;
  }

  .list-group-item {
    --bg-color: white;
    --swp-dist: 0px;
    border-radius: 0;
    cursor: pointer;
    border-inline: none;
    padding-left: 13px;
    width: 100%;
    position: relative;
    left: var(--swp-dist);
    transition: left var(--transition, 0s);

    &:hover,
    &:focus {
      --bg-color: #f5f5f5;
    }

    &.readonly {
      color: #555;

      &,
      &:hover {
        background: white;
      }

      &,
      .checkbox label {
        cursor: default;
      }
    }

    &.disabled {
      --bg-color: #eee;

      &,
      .checkbox label {
        cursor: default;
      }

      .list-group-item-text .icon {
        color: #777;
      }
    }

    .list-group-item-heading {
      font-size: 1.6rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      margin: 0;
    }

    .list-group-item-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .icon {
        font-size: 0.9em;
        color: #333;
      }
    }

    .actions {
      display: none;
      justify-content: right;
      align-items: center;
      position: absolute;
      right: -15px;
      top: 0;
      bottom: 0;
      padding-right: 15px;
      background: linear-gradient(to left, var(--bg-color) 88%, transparent);
      padding-left: 15px;
    }

    &:hover .actions {
      display: flex;
    }

    .checkbox {
      margin: 0;

      label {
        vertical-align: middle;
      }

      &.spaced input {
        margin-top: 6px;
      }
    }

    // temporally reduce label width during transition
    // to hide swipe action icon (TODO: find a better way of hiding it)
    &.transitioning .checkbox label {
      max-width: 97%;
    }

    @media (hover: hover) {
      &::before {
        content: "";
      }
    }

    @media (hover: none) {
      // swipe action icon
      &::before {
        z-index: 1;
        color: var(--bg-color);
        display: inline-block;
        font-family: "FontAwesome";
        font-size: 2rem;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        position: absolute;
        inset: 1.5rem Min(0px, calc(var(--swp-dist) + 32px - 0.5em)) 1rem auto;
        transition: right var(--transition, 0s);
      }

      &.transitioning::before {
        right: calc(var(--swp-dist) + 32px - 0.5em);
      }

      // swipe action background
      &::after {
        content: "";
        position: absolute;
        inset: -1px var(--swp-dist) 0 auto;
        width: calc(-1 * var(--swp-dist));
        transition: all var(--transition, 0s);
      }

      &.swipe-action-primary::after {
        background-color: #3c8dbc;
      }

      &.swipe-action-success::after {
        background-color: #00a65a;
      }

      &.swipe-action-info::after {
        background-color: #00c0ef;
      }

      &.swipe-action-warning::after {
        background-color: #f39c12;
      }

      &.swipe-action-danger::after {
        background-color: #dd4b39;
      }

      &:hover .actions {
        display: none;
      }
    }
  }
}
</style>
