<template>
  <div
    :class="['input-group', { [searchingClass]: query != '' }]"
    data-testid="query-container"
  >
    <input
      type="search"
      :class="[
        'form-control',
        { 'full-width': !(showSearchButton || canClear) }
      ]"
      :placeholder="placeholder"
      v-on="listeners"
      @keydown.esc="query = ''"
      v-model="query"
      data-testid="query"
      ref="input"
    />
    <span
      class="input-group-addon btn"
      @click="sideButtonAction"
      v-if="flatButton"
      :title="canClear ? $t('clear') : $t('search')"
      data-testid="side-btn-flat"
    >
      <i :class="'fa fa-' + (canClear ? 'close' : 'search')"></i>
    </span>
    <span class="input-group-btn" v-else>
      <button
        class="btn btn-default"
        type="button"
        @click="sideButtonAction"
        :title="canClear ? $t('clear') : $t('search')"
        data-testid="side-btn"
      >
        <i :class="'fa fa-' + (canClear ? 'close' : 'search')"></i>
      </button>
    </span>
  </div>
</template>

<script>
import omit from "lodash/omit";

export default {
  name: "Search",
  props: {
    value: {
      type: String,
      default: ""
    },
    showSearchButton: {
      type: Boolean,
      default: true
    },
    flatButton: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default() {
        return this.$t("search");
      }
    },
    searchingClass: {
      type: String,
      default: ""
    },
    useQuery: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      query: ""
    };
  },
  computed: {
    canClear() {
      return this.clearable && this.query != "";
    },
    listeners() {
      return omit(this.$listeners, ["input"]);
    }
  },
  watch: {
    value(val) {
      if (val != this.query) this.query = val;
    },
    query(val) {
      if (val != this.value) this.$emit("input", val);
    }
  },
  methods: {
    sideButtonAction() {
      if (this.canClear) {
        this.query = "";
        this.$emit("clear");
      } else {
        this.$refs.input.focus();
      }
    },
    setQuery() {
      if (this.useQuery && this.$route.query.q) {
        this.query = this.$route.query.q;
      } else {
        this.query = "";
      }
    }
  },
  mounted() {
    this.setQuery();
  },
  activated() {
    this.setQuery();
  }
};
</script>

<style scoped>
.btn .fa {
  width: 13px;
  height: 14px;
}

.form-control.full-width {
  width: calc(100% + 38px);
  z-index: 3;
}
</style>
