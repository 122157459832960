<template>
  <div class="process-areas-card" :title="hint">
    <ListCard
      v-bind="$attrs"
      :title="titleText"
      subtitleSuffix="a"
      :subtitleTerm="showSubtitleTerm ? 'process_area' : ''"
      :title-hint="usersEmails"
      :tooltip="tooltip"
      :list="processAreas"
      :selected="commonProcessAreas"
      :indeterminated="divergentProcessAreas"
      itemTitle="name"
      searchFields="name"
      activeClass=""
      :emptyListText="$t('no_process_areas')"
      :sort="true"
      @select="select"
      @gotDirty="updateDirty($event.length > 0)"
      :reset="listReset"
    >
      <template #footer v-if="(localDirty ? isDirty : dirty) || $slots.footer">
        <slot name="footer"></slot>
        <div
          class="form-actions"
          :style="!footerSpacing ? { 'text-align': 'right' } : {}"
        >
          <BaseButton
            v-if="users.length"
            type="danger"
            :small="isMobile"
            @click="localDirty ? clear() : $emit('clear')"
            style="margin-right: 2rem"
            >{{ $t("undo") }}</BaseButton
          >
          <BaseButton
            type="primary"
            :disabled="localDirty ? !isDirty : !dirty"
            :small="isMobile"
            @click="save"
            >{{ $t("save") }}</BaseButton
          >
        </div>
        <div class="spacing" v-if="$slots.footer && footerSpacing"></div>
      </template>
    </ListCard>
  </div>
</template>

<script>
import ListCard from "@/components/widgets/list-card";
import BaseButton from "@/components/base/buttons/base-button";
import MixinMobile from "@/project/mixin-mobile";

import { mapGetters } from "vuex";

export default {
  name: "ProcessAreasCard",
  mixins: [MixinMobile],
  components: {
    ListCard,
    BaseButton
  },
  inheritAttrs: false,
  props: {
    users: {
      type: Array,
      default: () => []
    },
    group: {
      type: [Object, Number]
    },
    // weather or not to track changes locally
    localDirty: {
      type: Boolean,
      default: true
    },
    dirty: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    hint: {
      type: String,
      default: ""
    },
    tooltip: {
      type: String,
      default: ""
    },
    showSubtitleTerm: {
      type: Boolean,
      default: false
    },
    footerSpacing: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedProcessAreas: null,
      isDirty: false,
      listReset: null,
      mobileThreshold: 768
    };
  },
  computed: {
    usersEmails() {
      return this.users.map((user) => user.email).join(", ") || this.hint;
    },
    titleText() {
      if (this.title) return this.title;
      switch (this.users.length) {
        case 0:
          return this.$tc("process_area", this.processAreas.length);
        case 1:
          return this.usersEmails;
        default:
          return `${this.users.length} ${this.$tc("user", 2).toLowerCase()} (${
            this.usersEmails
          })`;
      }
    },
    // process areas related to all users
    commonProcessAreas() {
      return this.processAreas.filter((pa) =>
        this.users.length
          ? this.users.every(this.userHasProcessArea(pa))
          : false
      );
    },
    // process areas related to some but not all users
    divergentProcessAreas() {
      return this.processAreas.filter(
        (pa) =>
          this.users.some(this.userHasProcessArea(pa)) &&
          !this.users.every(this.userHasProcessArea(pa))
      );
    },
    ...mapGetters("processArea", ["processAreas"])
  },
  watch: {
    titleText() {
      this.selectedProcessAreas = null;
      this.updateDirty(false);
      this.listReset = Math.random();
    }
  },
  methods: {
    select(processAreas) {
      this.selectedProcessAreas = processAreas;
      this.$emit("select", processAreas);
    },
    clear() {
      this.select([]);
      this.updateDirty(false);
      this.listReset = Math.random();
    },
    save() {
      const done = () => {
        this.isDirty = false;
      };
      this.$emit("save", this.selectedProcessAreas, done);
    },
    updateDirty(dirty) {
      this.isDirty = dirty;
      this.$emit("dirty", this.isDirty);
    },
    userHasProcessArea(pa) {
      return (user) =>
        user.process_area.some(
          (p) =>
            p.id == pa.id &&
            (this.group
              ? p.user_groups.some((g) => g.id == (this.group.id ?? this.group))
              : true)
        );
    }
  }
};
</script>

<style lang="scss" scoped>
.process-areas-card::v-deep .box-footer {
  display: flex;
  justify-content: center;

  .form-actions {
    text-align: center;
  }

  .form-actions,
  .spacing {
    flex: 1;
  }
}

.process-area-card__tooltip {
  position: absolute;
  right: 11.5%;
  top: 32%;

  @media (max-width: 1199px) {
    right: 11%;
  }
}
</style>
