<template>
  <Search v-bind="$attrs" v-on="listeners" v-model="query" />
</template>

<script>
import Search from "@/components/widgets/search";

import omit from "lodash/omit";

export default {
  name: "ListFilter",
  inheritAttrs: false,
  components: { Search },
  props: {
    list: {
      type: Array,
      required: true
    },
    fields: {
      type: String,
      default: ""
    },
    customFilter: {
      type: Function,
      default: null
    },
    q: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      query: ""
    };
  },
  computed: {
    listeners() {
      return omit(this.$listeners, ["filter"]);
    }
  },
  watch: {
    query: "filter",
    q: {
      immediate: true,
      handler(q) {
        this.query = q;
      }
    },
    list: {
      immediate: true,
      deep: true,
      handler: "filter"
    }
  },
  methods: {
    filter() {
      let list = this.list.filter((item) =>
        this.fields || this.customFilter
          ? (this.fields &&
              this.$utils.queryStrAtr(this.query, item, this.fields)) ||
            (this.customFilter && this.customFilter(item, this.query))
          : true
      );
      this.$emit("filter", list);
    }
  }
};
</script>

<style></style>
