<template>
  <div class="groups-card" :title="hint">
    <ListCard
      v-bind="$attrs"
      :title="titleText"
      :subtitleTerm="showSubtitleTerm ? 'group' : ''"
      :title-hint="usersEmails"
      :tooltip="tooltip"
      :list="groups"
      :selected="commonGroups"
      :indeterminated="divergentProcessAreas"
      itemTitle="name"
      searchFields="name"
      activeClass=""
      :emptyListText="$t('no_groups')"
      :sort="true"
      @select="select"
      @gotDirty="updateDirty($event.length > 0)"
      :reset="listReset"
    >
      <template #footer v-if="(localDirty ? isDirty : dirty) || $slots.footer">
        <slot name="footer"></slot>
        <div
          class="form-actions"
          :style="!footerSpacing ? { 'text-align': 'right' } : {}"
        >
          <BaseButton
            v-if="users.length"
            type="danger"
            :small="isMobile"
            @click="localDirty ? clear() : $emit('clear')"
            style="margin-right: 2rem"
            >{{ $t("undo") }}</BaseButton
          >
          <BaseButton
            type="primary"
            :disabled="localDirty ? !isDirty : !dirty"
            :small="isMobile"
            @click="save"
            >{{ $t("save") }}</BaseButton
          >
        </div>
        <div class="spacing" v-if="$slots.footer && footerSpacing"></div>
      </template>
    </ListCard>
  </div>
</template>

<script>
import ListCard from "@/components/widgets/list-card";
import BaseButton from "@/components/base/buttons/base-button";
import MixinMobile from "@/project/mixin-mobile";

import { mapGetters } from "vuex";

export default {
  name: "GroupsCard",
  mixins: [MixinMobile],
  components: {
    ListCard,
    BaseButton
  },
  inheritAttrs: false,
  props: {
    users: {
      type: Array,
      default: () => []
    },
    processArea: {
      type: [Object, Number]
    },
    // weather or not to track changes locally
    localDirty: {
      type: Boolean,
      default: true
    },
    dirty: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    hint: {
      type: String,
      default: ""
    },
    tooltip: {
      type: String,
      default: ""
    },
    showSubtitleTerm: {
      type: Boolean,
      default: false
    },
    footerSpacing: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedGroups: null,
      isDirty: false,
      listReset: null,
      mobileThreshold: 768
    };
  },
  computed: {
    usersEmails() {
      return this.users.map((user) => user.email).join(", ") || this.hint;
    },
    titleText() {
      if (this.title) return this.title;
      switch (this.users.length) {
        case 0:
          return this.$tc("group", this.groups.length);
        case 1:
          return this.usersEmails;
        default:
          return `${this.users.length} ${this.$tc("user", 2).toLowerCase()} (${
            this.usersEmails
          })`;
      }
    },
    // groups related to all users
    commonGroups() {
      return this.groups.filter((g) =>
        this.users.length ? this.users.every(this.userHasGroup(g)) : false
      );
    },
    // groups related to some but not all users
    divergentProcessAreas() {
      return this.groups.filter(
        (g) =>
          this.users.some(this.userHasGroup(g)) &&
          !this.users.every(this.userHasGroup(g))
      );
    },
    ...mapGetters("role", { groups: "roles" })
  },
  watch: {
    titleText() {
      this.selectedGroups = null;
      this.updateDirty(false);
      this.listReset = Math.random();
    }
  },
  methods: {
    select(groups) {
      this.selectedGroups = groups;
      this.$emit("select", groups);
    },
    clear() {
      this.select([]);
      this.updateDirty(false);
      this.listReset = Math.random();
    },
    save() {
      const done = () => {
        this.isDirty = false;
      };
      this.$emit("save", this.selectedGroups, done);
    },
    updateDirty(dirty) {
      this.isDirty = dirty;
      this.$emit("dirty", this.isDirty);
    },
    userHasGroup(group) {
      return (user) =>
        user.process_area
          .find((p) => p.id == this.processArea.id)
          ?.user_groups.some((g) => g.id == group.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.groups-card::v-deep .box-footer {
  display: flex;
  justify-content: center;

  .form-actions {
    text-align: center;
  }

  .form-actions,
  .spacing {
    flex: 1;
  }
}

// .groups-card__tooltip {
//   position: absolute;
//   right: 11.5%;
//   top: 32%;

//   @media (max-width: 1199px) {
//     right: 11%;
//   }
// }
</style>
