<script>
export default {
  name: "ListGroup",
  props: {
    linked: {
      type: Boolean,
      default: false
    },
    stripped: {
      type: Boolean,
      default: false
    },
    reverseStrip: {
      type: Boolean,
      default: false
    }
  },
  provide() {
    return {
      linked: this.linked
    };
  },
  render(h) {
    if (
      this.$slots.default?.every?.((vnode) =>
        vnode.tag.endsWith("ListGroupItem")
      )
    )
      return h(
        this.linked ? "div" : "ul",
        {
          class: [
            "list-group",
            {
              "stripped-even": this.stripped && this.reverseStrip,
              "stripped-odd": this.stripped && !this.reverseStrip
            }
          ]
        },
        this.$slots.default
      );
    else return h(this.linked ? "div" : "ul");
  }
};
</script>

<style scoped>
.list-group.stripped-even
  .list-group-item:not(.active):not(:hover):nth-of-type(even),
.list-group.stripped-odd
  .list-group-item:not(.active):not(:hover):nth-of-type(odd) {
  background: #f9f9f9;
}
</style>
